<template>
  <div style="height: inherit">
    <page-loader v-if="loading" type="list" :fluid="true" />
    <portal to="nav-bar-left-column">
      <page-title title="GraphQL" subtitle="Compose and Test you GraphQL queries" />
    </portal>
    <iframe ref="graphqlContainer" class="w-100 h-100 graphqlFrame" @load="load"> </iframe>
  </div>
</template>

<script>
import axios from 'axios'
import page from 'raw-loader!./graphiql.html'

export default {
  components: {},

  data() {
    return {
      loading: true,
    }
  },
  computed: {},
  mounted() {
    // const instance = axios.create({
    //   baseURL: '/',
    //   timeout: 1000,
    // })
    // let doc = await instance.get('/graphiql-playground').then(response => {
    //   let html = response.data
    //   return html.replace('{__token__}', self.$store.getters.Token.access_token)
    // })

    const self = this
    let doc = page.replace('{__token__}', this.$store.getters.Token.access_token).replace('{__project__}', this.projectName)

    self.$refs.graphqlContainer.contentWindow.document.open()
    self.$refs.graphqlContainer.contentWindow.document.write(doc)
    self.$refs.graphqlContainer.contentWindow.document.close()
  },
  methods: {
    load() {
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/graphql/graphql.scss';
</style>
